import * as bootstrap from 'bootstrap5';
import IMask from 'imask';
import './vendors/slick/slick.css';
import './vendors/slick/slick-theme.css';
import './vendors/slick/slick';

"use strict";

function ready(fn) {
    if (document.readyState != 'loading') {
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}

const isNumber = function(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
};

const scrollClick = function(e) {
    e.preventDefault();
    document.querySelector(this.getAttribute('href')).scrollIntoView({
        behavior: 'smooth',
        block: 'center'
    });
};

const insertAfter = (referenceNode, newNode, options = {tag: 'div', classList : ['invalid-feedback', 'invalid-feedback-backend']}) => {
    if (typeof newNode === 'string')
    {
        const el = document.createElement(options.tag || 'p');
        el.classList.add(...(options.classList || []));
        el.innerHTML = newNode;
        newNode = el;
    }
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling)
    return newNode;
};

const validateFileSize = (form, maxSizeMB = 3) => {
    const inputs = form.querySelectorAll('input[type=file]');
    let isValid = true;
    inputs.forEach(fileInput => {
        Array.from(fileInput.files).forEach(file => {
            if(file.size  > maxSizeMB * 1024 * 1024) {
                fileInput.setCustomValidity('invalid');
                isValid = false;
            } else {
                fileInput.setCustomValidity('');
            }
        })
    });
    if(!isValid) {
      form.classList.add('was-validated');
      $('.custom-file .form-label').css('background-image', 'none').html('<span><strong>Dodaj zdjęcie</strong>lub przeciągnij</span><br /><em>Minimalny rozmiar: 800 px</em>');
    }
    return isValid;
};

const openPopup = function(url) {
    var leftPosition, topPosition, width = 600, height = 300;
    leftPosition = (window.screen.width / 2) - ((width / 2) + 10);
    topPosition = (window.screen.height / 2) - ((height / 2) + 50);
    var windowFeatures = "status=no,height=" + height + ",width=" + width + ",resizable=yes,left=" + leftPosition + ",top=" + topPosition + ",screenX=" + leftPosition + ",screenY=" + topPosition + ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no";
    window.open(url, 'sharer', windowFeatures);
    return false;
};

var mainNav = document.getElementById("mainNav");
window.onscroll = function() {
    if(mainNav) fixedNav()
};
function fixedNav() {
    if (window.scrollY > 0) {
        mainNav.classList.add("sticky");
    } else {
        mainNav.classList.remove("sticky");
    }
}

jQuery.fn.toggleValid = function(valid) {
    this[0].setCustomValidity(valid ? "" : 'invalid');
}

ready(function() {
    const scrollLinks = document.querySelectorAll('.scroll-link');
    Array.prototype.forEach.call(scrollLinks, function(el, i) {
        el.addEventListener('click', scrollClick);
    });
    $('[data-same-as]').each(function() {
        var $input = $(this);
        var $sameAs = $($(this).data('same-as'));
        $(this).add($sameAs).on('input', function () {
            $input.toggleValid($input.val() == $sameAs.val());
            if($input.val() != $sameAs.val()) {
                $input.next('.invalid-feedback').text('Hasła nie są takie same');
            }
        });
    });
    $('.numberonly-field').each(function() {
        $(this).on('keypress', function(e) {
            return isNumber(e);
        }).on('paste', function (e) {
            if(e.originalEvent.clipboardData.getData('Text').match(/[^\d]/)) {
                e.preventDefault();
                var newVal = $(this).val()+""+e.originalEvent.clipboardData.getData('Text').replace(/[^0-9]/g, "");
                $(this).val(newVal.substring(0,$(this).attr('maxlength')));
            }
        });
    });
    $('#mainSlider').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: false
    });
    $('input[name=amount]').on('change', function(e) {
        e.preventDefault();
        if($('#amountManual').is(':checked')) {
            $('#amountManualInput').val('');
            $('.amount-manual-parent').removeClass('d-none');
        } else {
            $('#amountManualInput').val($(this).val());
            $('.amount-manual-parent').addClass('d-none');
        }
    });
    $('#amountManualInput').on('change', function() {
        $(this).val(parseInt($(this).val()));
        if(parseInt($(this).val()) <= 0) {
            $(this)[0].setCustomValidity('invalid');
        } else {
            $(this)[0].setCustomValidity('');
        }
    });
    $('select').on('change', function(e) {
        e.preventDefault();
        if($(this).val().length) {
            $(this).removeClass('empty');
        } else {
            $(this).addClass('empty');
        }
    });
    let notSoAnonymous = '';
    $('#anonymous').on('change', function(e) {
        e.preventDefault();
        if($(this).is(':checked')) {
            notSoAnonymous = $('#name').val();
            $('#name').val('Anonimowy Darczyńca').prop('readonly', true);
        } else {
            $('#name').val(notSoAnonymous).prop('readonly', false);
        }
    });
    $('.copy-value').on('click', function(e) {
        e.preventDefault();
        let copyBtn = $(this);
        let copyTarget = $(this).attr('data-copy');
        navigator.clipboard.writeText($(copyTarget).val()).then(
            () => {
                copyBtn.text('Skopiowany!');
                setTimeout(() => {
                    copyBtn.text('Kopiuj link');
                }, 10000);
            },
            () => {
                copyBtn.text('Błąd');
                setTimeout(() => {
                    copyBtn.text('Kopiuj link');
                }, 3000);
            }
        );
    });
    $('.new-fundraiser-form').on('change', '#voivodeship2', function() {
        if($(this).val().length) {
            $(this).removeClass('empty');
            var id = $(this).val();
            var url = $(this).data('url');
            if(id > 0){
                $.ajax({
                    type: 'POST',
                    dataType: 'json',
                    url: url,
                    data: { region : id },
                    success: function(response) {
                        $('#powiat')
                            .find('option')
                            .remove()
                            .end()
                            .append('<option selected value="">Powiat</option>')
                        $.each(response.districts, function(i, item) {
                            $("#powiat").append($('<option>', {
                                value: i,
                                text : item,
                            }));
                        });
                    }
                });
            }
            $('.new-fundraiser-form #powiat').prop('disabled', false);
        } else {
            $(this).addClass('empty');
            $('.new-fundraiser-form #powiat').val('').prop('disabled', true);
            $('.new-fundraiser-form #gmina').val('').prop('disabled', true);
            $('.new-fundraiser-form #placowka').val('').prop('disabled', true);
            $('.new-fundraiser-form .brakPlacowki').addClass('disabled');
        }
    });
    $('.new-fundraiser-form').on('change', '#powiat', function() {
        if($(this).val().length) {
            $(this).removeClass('empty');
            var id = $(this).val();
            var url = $(this).data('url');
            if(id > 0){
                $.ajax({
                    type: 'POST',
                    dataType: 'json',
                    url: url,
                    data: { district : id },
                    success: function(response) {
                        $('#gmina')
                            .find('option')
                            .remove()
                            .end()
                            .append('<option selected value="">Gmina</option>')
                        $.each(response.communes, function(i, item) {
                            $("#gmina").append($('<option>', {
                                value: i,
                                text : item,
                            }));
                        });
                    }
                });
            }
            $('.new-fundraiser-form #gmina').prop('disabled', false);
        } else {
            $(this).addClass('empty');
            $('.new-fundraiser-form #gmina').val('').prop('disabled', true);
            $('.new-fundraiser-form #placowka').val('').prop('disabled', true);
            $('.new-fundraiser-form .brakPlacowki').addClass('disabled');
        }
    });
    $('.new-fundraiser-form').on('change', '#gmina', function() {
        if($(this).val().length) {
            $(this).removeClass('empty');
            var id = $(this).val();
            var url = $(this).data('url');
            if(id > 0){
                $.ajax({
                    type: 'POST',
                    dataType: 'json',
                    url: url,
                    data: { commune : id },
                    success: function(response) {
                        $('#placowka')
                            .find('option')
                            .remove()
                            .end()
                            .append('<option selected="" value="">Wybierz swoją placówkę z listy</option>')
                        $.each(response.facilities, function(i, item) {
                            $("#placowka").append($('<option>', {
                                value: i,
                                text : item,
                            }));
                        });
                    }
                });
            }
            $('.new-fundraiser-form #placowka').prop('disabled', false);
            $('.new-fundraiser-form .brakPlacowki').removeClass('disabled');
        } else {
            $(this).addClass('empty');
            $('.new-fundraiser-form #placowka').val('').prop('disabled', true);
            $('.new-fundraiser-form .brakPlacowki').addClass('disabled');
        }
    });
    $('.new-fundraiser-form').on('change', '#placowka', function() {
        if($(this).val().length) {
            var id = $(this).val();
            var url = $(this).data('url');
            if(id > 0){
                $.ajax({
                    type: 'POST',
                    dataType: 'json',
                    url: url,
                    data: { facility : id },
                    success: function(response) {
                        console.log(response)
                        if (response.economy) {
                            $("#project1").attr("disabled", true);
                            $('label[for="project1"]').addClass('is-invalid');
                            $('.project1 div.invalid-feedback').text(response.economyError);
                        } else {
                            $("#project1").attr("disabled", false);
                            $('label[for="project1"]').removeClass('is-invalid');
                        }
                        if (response.empathy) {
                            $("#project2").attr("disabled", true);
                            $('label[for="project2"]').addClass('is-invalid');
                            $('.project2 div.invalid-feedback').text(response.empathyError);
                        } else {
                            $("#project2").attr("disabled", false);
                            $('label[for="project2"]').removeClass('is-invalid');
                        }
                        if (response.economy && response.empathy) {
                            $("#createFundraising").attr("disabled", true);
                        } else {
                            $("#createFundraising").attr("disabled", false);
                        }
                    }
                });
            }
        }
    });
    $('.edit-field').on('click', function(e) {
        e.preventDefault();
        $(this).addClass('d-none');
        $(this).siblings('input').prop('readonly', false);
        if($(this).hasClass('pwd')) {
            $(this).siblings('input').val('');
            $('.pwd_helper').removeClass('d-none');
        }
        $(this).siblings('input').focus();
    });
    $('#zdjecie').on('change', function(e) {
        //$(this).next('.form-label').find('span').html('<strong>'+e.target.files[0].name+'</strong>');
        let frame = $(this).next('.form-label');
        let frameParent = $(this).parent('.custom-file');
        frame.html('');
        frame[0].style.backgroundImage = 'url('+URL.createObjectURL(e.target.files[0])+')';
        frameParent.siblings('.form-label').text('Zmień');
    });
    const brakPlacowkiFieldset = document.getElementById('brakPlacowkiFieldset')
    if(brakPlacowkiFieldset) brakPlacowkiFieldset.addEventListener('show.bs.collapse', () => {
        $('#brakPlacowkiFieldset').find('input:not(.noreq), textarea').prop('required', true);
        $('#placowka').prop('required', false);
    });
    if(brakPlacowkiFieldset) brakPlacowkiFieldset.addEventListener('hide.bs.collapse', () => {
        $('#brakPlacowkiFieldset').find('input:not(.noreq), textarea').val('').prop('required', false);
        $('#placowka').prop('required', true);
    });
    const registersocialModal = document.getElementById('registersocialModal');
    if(registersocialModal) {
        const registersocialModalObj = new bootstrap.Modal(registersocialModal, {
            backdrop: 'static',
            keyboard: false
        });
        registersocialModalObj.show();
    }
    const shareModal = document.getElementById('shareModal');
    if(shareModal) shareModal.addEventListener('show.bs.modal', function(e) {
        let shareurl = e.relatedTarget.getAttribute('data-shareurl');
        document.getElementById('link_do_zbiorki').value = shareurl;
        document.querySelector('#shareModal .facebook').setAttribute('data-shareurl', shareurl);
        document.querySelector('#shareModal .linkedin').setAttribute('data-shareurl', shareurl);
    });
    $('#shareModal').on('click', '.facebook', function(e) {
        e.preventDefault();
        openPopup('https://www.facebook.com/sharer.php?u='+encodeURIComponent($(this).attr('data-shareurl'))+'&t='+encodeURIComponent("Udostępnij zbiórkę na Facebooku"));
    });
    $('#shareModal').on('click', '.instagram', function(e) {
        e.preventDefault();
        alert('share instagram: '+$(this).attr('data-shareurl'));
    });
    $('#shareModal').on('click', '.linkedin', function(e) {
        e.preventDefault();
        openPopup('https://www.linkedin.com/sharing/share-offsite/?url='+encodeURIComponent($(this).attr('data-shareurl')));
    });
    $('.teamSlider').slick({
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        responsive: [
          {
            breakpoint: 1401,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 1201,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 1
            }
          }
        ]
    });
    const empatiaTabPill = document.getElementById('empatiaTabPill');
    if(empatiaTabPill) {
        empatiaTabPill.addEventListener('shown.bs.tab', () => {
            $('body').addClass('empatia-bg');
        });
        empatiaTabPill.addEventListener('hidden.bs.tab', () => {
            $('body').removeClass('empatia-bg');
        });
    }

    // AJAX FORMS
    $('#accountEditForm').on('submit', function(e) {
        e.preventDefault();
        this.classList.add('was-validated');
        $(this).find('input').each(function() {
            $(this)[0].setCustomValidity('');
        });
        if(this.checkValidity() === false) {
            e.stopPropagation();
            $(this).find('input:invalid').first().focus();
            return false;
        } else {
            sendFormAjax(this);
        }
        return false;
    });
    $('#registrationForm, #registrationFormModal').on('submit', function(e) {
        e.preventDefault();
        this.classList.add('was-validated');
        $(this).find('input').each(function() {
            $(this)[0].setCustomValidity('');
        });
        if($('#amountManualInput').length) {
            if(parseInt($('#amountManualInput').val()) <= 0) {
                $('#amountManualInput')[0].setCustomValidity('invalid');
            } else {
                $('#amountManualInput')[0].setCustomValidity('');
            }
        }
        if(this.checkValidity() === false) {
            e.stopPropagation();
            $(this).find('input:invalid').first().focus();
            return false;
        } else {
            sendFormAjax(this);
        }
        return false;
    });
    $('#newFundraiserForm').on('change', 'input[type=file]', function(e) {
        validateFileSize($('#newFundraiserForm')[0]);
    });
    $('#newFundraiserForm').on('submit', function(e) {
        this.classList.add('was-validated');
        $(this).find('input').each(function() {
            $(this)[0].setCustomValidity('');
        });
        if(!validateFileSize(this)) {
            return false;
        }
        if(this.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            $(this).find('input:invalid').first().focus();
            return false;
        } else {
            return true;
        }
        return false;
    });
    let recoverpassFormSent = false;
    $('#recoverpassForm').on('submit', function(e) {
        e.preventDefault();
        this.classList.add('was-validated');
        $(this).find('input').each(function() {
            $(this)[0].setCustomValidity('');
        });
        var $input = $('[data-same-as]');
        if($input.length) {
            var $sameAs = $($input.data('same-as'));
            $input.toggleValid($input.val() == $sameAs.val());
            if($input.val() != $sameAs.val()) {
                $input.next('.invalid-feedback').text('Hasła nie są takie same');
            }
        }
        if(this.checkValidity() === false) {
            e.stopPropagation();
            $(this).find('input:invalid').first().focus();
            return false;
        } else {
            if(recoverpassFormSent === false) {
                recoverpassFormSent = true;
                sendFormAjax(this, (response) => {
                    if(response.error && response.error.length) { //string
                        $('input[name="recover_password_form[email]"]').siblings('.invalid-feedback').text(response.error);
                        $('input[name="recover_password_form[email]"]')[0].setCustomValidity(response.error);
                        $(form).find('input:invalid').first().focus();
                    }
                }, () => {
                    recoverpassFormSent = false;
                });
            }
        }
        return false;
    });
    let dontResetSearch = false;
    $('#searchBarForm').on('submit', function(e) {
        e.preventDefault();
        if(!dontResetSearch) {
            $('#searchBarForm input[name=page]').val(1);
        } else {
            dontResetSearch = false;
        }
        sendFormAjax(this, (response) => {
            if(response.html && response.html.length && response.container) {
                var container = $('#' + response.container);
                container.html(response.html);
                container[0].scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
            }
        });
        return false;
    });
    $('#sortorder').on('change', function(e) {
        dontResetSearch = true;
        $('#searchBarForm').submit();
    });
    $('.grid-section').on('click', '.page-link', function(e) {
        e.preventDefault();
        let page = $(this).data('page');
        $('#searchBarForm input[name=page]').val(page);
        dontResetSearch = true;
        $('#searchBarForm').submit();
        return false;
    });
    const sendFormAjax = (form, callbackFn, errorFallbackFn) => {
        grecaptcha.ready(function () {
            grecaptcha.execute(RECAPTCHA_KEY, {}).then(function (token) {
                var formData = new FormData(form);
                formData.append('g-recaptcha-response', token);
                $.ajax({
                    method: "POST",
                    url: form.getAttribute('action'),
                    data: formData,
                    dataType: "json",
                    processData: false,
                    contentType: false
                })
                .done(function(response) {
                    if(response.status == 'ok' || response.state == 'ok' || response.error) {
                        if(callbackFn) callbackFn(response);
                        if(response.redirect && response.redirect.length) {
                            window.location.href = response.redirect;
                        }
                    } else if(response.status == 'error' || response.state == 'error') {
                        if(errorFallbackFn) errorFallbackFn();
                        if(response.errors && Object.keys(response.errors).length) { //object { name1: error1, name2: error2, name3: error3, ... }
                            for(let err in response.errors) {
                                $('input[name="'+err+'"]').siblings('.invalid-feedback').text(response.errors[err]);
                                $('input[name="'+err+'"]')[0].setCustomValidity(response.errors[err]);
                            }
                            $(form).find('input:invalid').first().focus();
                        }
                    }
                })
                .fail(function(jqXHR, reason) {
                    if(errorFallbackFn) errorFallbackFn();
                    console.log("Request failed1: " + reason);
                });
            }).catch(function(reason) {
                if(errorFallbackFn) errorFallbackFn();
                console.log("Request failed2: " + reason);
            });
        });
        return false;
    };
    var maskZip = document.getElementById('zipcode');
    if (maskZip) {
        var maskZipObj = new IMask(maskZip, {
            mask: '00-000',
            lazy: true,
            placeholderChar: '_',
            commit: function (value, masked) {
                masked._value = value.replaceAll(" ", "");
            }
        });
        maskZip.addEventListener('focus', function() {
          maskZipObj.updateOptions({ lazy: false });
        }, true);
        maskZip.addEventListener('blur', function() {
          maskZipObj.updateOptions({ lazy: true });
          // NEXT IS OPTIONAL
          // if (!maskNrKontaObj.masked.rawInputValue) {
          // 	maskNrKontaObj.value = '';
          // }
        }, true);
    }
});

$('.logos').each(function () {
    $(this).slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        mobileFirst: true,
        adaptiveHeight: true,
        autoplay: true,
        centerMode: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: $(this).find('img').length > 3 ? 3 : $(this).find('img').length
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: $(this).find('img').length > 7 ? 7 : $(this).find('img').length
                }
            }
        ]
    });
});
